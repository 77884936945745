import React from 'react';

import { Nav } from '../components/Nav';
import { Intro } from './parts/Intro';
import { Content } from './parts/Content';

const Home = () => {
  return (
    <>
      <Nav />
      <Intro />
      <Content />
    </>
  );
};

export { Home };
