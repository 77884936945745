import React from 'react';
import { NavPages } from '../components/NavPages';

const Error = () => {
  return (
    <>
      <NavPages />
      <section>
        <div className='container tc'>
          <div className='s-70'></div>
          <div className='w-100 flex aic jcc'>
            <div className='h-40'>
              <h3 className='title c-theme'>Сторінку не знайдено</h3>
              <div className='s-50'></div>
              <div className='flex tc jcc'>
                <p>Перевірте правильність введеної адреси</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export { Error };
